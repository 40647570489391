import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import Layout from "../../components/layout";
//import Markdown from "react-markdown";
import ReactMarkdown from "react-markdown";
import Image from "~/components/image";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import { ArrowRightIcon } from "@heroicons/react/outline";
import ZohoCampaignBlog from "~/components/ZohoCampaignBlog";
import TourCarousel from "~/components/TourCarousel";

export const query = graphql`
	query ArticleQuery($slug: String!) {
		strapiArticle(slug: { eq: $slug }) {
			strapiId
			title
			dURL
			content
			slug
			published_at
			updated_at
			section {
				id
				heading
				detail
				cta
				ctaURL
				ctaText
				ctasubText
				newsl
			}
			image {
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(aspectRatio: 1.3, layout: FULL_WIDTH)
					}
				}
			}
			category {
				name
			}
			region {
				name
			}
			tours {
				id
				duration
				price
				slug
				title
				popular
				new
				featuredImage {
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								layout: FULL_WIDTH
								placeholder: BLURRED
								aspectRatio: 1.3
							)
						}
					}
				}
				quickfacts {
					lodging
					level
				}
			}

			author {
				name
				slug
				image {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 50, height: 50)
						}
					}
				}
			}
		}
	}
`;

const Article = ({ data }) => {
	const article = data.strapiArticle;
	//const toursInfo = article.tours;
	const seo = {
		metaTitle: article.title,
		metaDescription: article.description,
		shareImage: article.image,
		article: true,
	};

	// Define UTM parameters
	const viewToursUTM = new URLSearchParams({
		utm_source: "blog",
		utm_medium: "cta-button",
		utm_campaign: "view-tours",
	}).toString();

	// Combine the existing URL with UTM parameters
	//const urlWithUTM = `${node.ctaURL}?${viewToursUTM}`;

	return (
		<Layout>
			<GatsbySeo
				title={article.title}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/blog/${article.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/blog/${article.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/blog/${article.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/blog/${article.slug}`,
				// 	},
				// ]}
			/>

			{/* start */}
			{/* {article.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={article.image}
						alt={`Picture for ${article.title} article`}
						loading="lazy"
					/>
				</div>
			)} */}

			{/* like Hero */}
			<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="flex flex-col lg:flex-row items-center">
					{/* Left Half */}
					<div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<p className="text-center font-semibold text-gray-600 mb-6">
								{article.category.name} | Last updated:{" "}
								<Moment format="MMM Do YYYY">{article.updated_at}</Moment>
							</p>
							<h1 className="text-2xl text-black font-normal sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl">
								{/* <span className="font-light   ">Premier</span>{" "} */}
								<span className="text-black font-bold">{article.title}</span>
							</h1>
							<div className="text-center mb-14 mt-6">
								<p className=" font-light">
									By{" "}
									<Link to={`/authors/${article.author.slug}`} className="underline">
										{article.author.name}
									</Link>
									{" • "}
									<Moment format="MMM Do YYYY">{article.published_at}</Moment>
								</p>
							</div>

							<div className="flex flex-col w-full md:w-auto md:flex-row  md:space-x-4">
								<a
									href="#explore"
									className="bg-primary mb-4 md:mb-0 capitalize tracking-wide font-semibold text-white px-4 py-3 md:py-4 md:px-8 lg:px-5 xl:px-8 rounded-full"
								>
									Explore trips
								</a>
								<a
									href="#guide"
									className="bg-white capitalize border font-semibold tracking-wide  border-primary text-primary px-4 py-3 md:py-4 md:px-6 lg:px-4 xl:px-6 rounded-full"
								>
									Read guide
								</a>
							</div>
						</div>
					</div>

					{/* Right Half */}
					<div className="lg:w-1/2 mt-2 lg:mt-0">
						<Image
							className="rounded-lg shadow-md transform border border-gray-200"
							image={article.image}
							alt={`Picture for ${article.title} article`}
							loading="lazy"
						/>
					</div>
				</div>
			</section>

			<section id="explore" className="mb-28">
				{/* explore our tours */}
				{article.region.name && article.tours.length > 0 && (
					<div>
						<h3 className="mx-auto uppercase mt-28 mb-6">
							Explore our {article.region.name} bike tours
						</h3>
						<div>
							<Link to={article.dURL} className="my-4 sm:my-0">
								<span className="px-5 py-2  text-sm border border-primary text-primary bg-white rounded-full">
									View all tours
								</span>
							</Link>
						</div>
						<div className="mt-10 mb-20">
							<TourCarousel tours={article.tours} />
						</div>
					</div>
				)}
			</section>

			<section id="guide">
				{/* Detailed Article */}
				{article.section.length > 0 && (
					<div className="flex flex-col lg:flex-row lg:space-x-8">
						<div className="w-full lg:w-1/4">
							<div className="lg:sticky top-20">
								<h3 className="text-xl font-semibold mb-4">Table of Contents</h3>

								<ul className="space-y-2">
									{article.section.map((node, index) => {
										const sectionNumber = index + 1; // Add 1 to start numbering from 1
										return (
											<li key={node.id}>
												<a href={`#${node.id}`} className="text-blue-500 hover:underline">
													{`${sectionNumber}. ${node.heading}`}
												</a>
											</li>
										);
									})}
								</ul>
							</div>
						</div>

						<div className="w-full lg:w-3/4">
							{article.section.map((node) => {
								return (
									<div key={node.id} id={node.id}>
										{/* Article Start */}
										<div className="m-auto">
											<h2 className="mt-10 lg:mt-10 mb-10">{node.heading}</h2>
											<ReactMarkdown className="prose mb-20" children={node.detail} />
										</div>

										{node.cta == true && (
											// <div>
											// 	<TourCarousel tours={article.tours} />
											// </div>

											<div className="flex flex-col bg-landing rounded-lg px-6 py-10 mb-20 md:px-8 md:py-20 xl:flex-row xl:justify-between items-center">
												<div className="max-w-xl text-center lg:text-left">
													<h3 className="tracking-wide font-semibold text-black uppercase text-2xl md:text-3xl">
														{node.ctaText}
													</h3>
													<p className=" mt-2 text-lg md:text-xl">{node.ctasubText}</p>
												</div>
												<div className="mt-10 lg:mt-10 xl:mt-0">
													<button className="">
														<Link
															to={`${node.ctaURL}?${viewToursUTM}`}
															className="text-white font-medium tracking-wide bg-articlecta px-6 py-3 md:py-4 md:px-4 rounded-full "
														>
															View bike tours{" "}
															<ArrowRightIcon className="h-4 w-4 inline-flex" />
														</Link>
													</button>
												</div>
											</div>
										)}
										{/* Newsletter CTA */}
										{/*
											{node.newsl == true && (
												<div className="my-20 bg-gray-50 rounded-lg py-6">
													<h3 className="text-center">
														Love what you're reading? Dive deeper into similar content!
													</h3>
													<p className="text-center my-4 max-w-xl mx-auto">
														Stay updated by signing up. We'll email you when new articles are
														published on this topic.
													</p>
													<ZohoCampaignBlog />
												</div>
											)}
											*/}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</section>

			<section>
				{/* explore our tours */}
				{article.region.name && article.tours.length > 0 && (
					<div>
						<hr className="my-16"></hr>
						<h3 className="mx-auto uppercase text-center mb-10">
							Explore our {article.region.name} bike tours
						</h3>
						<div className="mb-20">
							<TourCarousel tours={article.tours} />
						</div>
					</div>
				)}
			</section>

			{/* <div className="">
				<div className="my-14">
					<p className="text-center font-semibold text-gray-600">
						{article.category.name} | Last updated:{" "}
						<Moment format="MMM Do YYYY">{article.updated_at}</Moment>
					</p>
					<h1 className="mb-4 mt-10 text-center">{article.title}</h1>
					<div>
						{article.image && (
							<div className="text-center">
								<Image
									className="rounded-full shadow-md transform"
									image={article.author.image}
									alt={`Picture of ${article.author.name}`}
									loading="lazy"
								/>
							</div>
						)}
					</div>
					<div className="text-center mb-14 mt-4">
						<p className=" font-light">
							By{" "}
							<Link to={`/authors/${article.author.slug}`} className="underline">
								{article.author.name}
							</Link>
							{" • "}
							<Moment format="MMM Do YYYY">{article.published_at}</Moment>
						</p>
					</div>

					<ReactMarkdown className="prose m-auto mb-14" children={article.content} />

					<Markdown source={article.content} escapeHtml={false} />

					<hr className="" />

					<div className="">
						<div>
							{article.image && (
								<div className="">
									<Image
										className="rounded-lg shadow-md transform"
										image={article.author.picture}
										alt={`Picture of ${article.author.username}`}
										loading="lazy"
									/>
								</div>
							)}
						</div>
						<div className="">
							<p className="">By {article.author.username}</p>
							<p className="uk-text-meta uk-margin-remove-top">
									<Moment format="MMM Do YYYY">{article.published_at}</Moment>
								</p>
						</div>
					</div>
				</div>
			</div> */}
		</Layout>
	);
};

export default Article;
